
import Fidget, { FidgetConfig, PointEvent, SharedStateEntity } from '../../Core/FidgetClass';
import AyisenMath from '../../../AyisenMath';
import FidgetUtils from "../../../Utilities";
import { FidgetId } from '../../../../constants/fidgetConsts';

// JS Library for Ripple Animations

interface ShimmerSquare {
    activationT: number,
    color: string,
}

interface ShimmerGrid extends Array<ShimmerSquare | null>{};


export default class Paths extends Fidget {


    shimmerWidth: number = 5;

    activeColor: string = AyisenMath.Random.randomColorNoAlpha();

    constructor(canvas: HTMLCanvasElement, config: FidgetConfig = {}){

        super(canvas, FidgetId.PATHS, config);

        // Simmer management
        this.reInitShimmerGrid();

        this.resetActiveColor();

    }

    mouseUp = (e: PointEvent) => {
        this.resetActiveColor();
    }


    resetActiveColor() {
        this.activeColor = AyisenMath.Random.randomColorNoAlpha();
    }

    getGridDims() {
        return {
            w: Math.floor(this.canvas.width/this.shimmerWidth),
            h: Math.floor(this.canvas.height/this.shimmerWidth),
        }
    }

    reInitShimmerGrid() {

        // 1D array with CanvasW/shimmerW*CanvasH/shimmerW dims. 
        const shimmerGrid = [];

        const gridDims = this.getGridDims();

        for (let i=0; i< gridDims.w; i++) {
            for (let j=0; j < gridDims.h; j++) {
                shimmerGrid.push(null);
            }
        }

        // Write to shared state
        this.sharedState.write(shimmerGrid, SharedStateEntity.FIDGET)

        // Filled!

    }

    getShimmerLoc(idx: number) {

        const gridDims = this.getGridDims();

        const x = ((idx % gridDims.w) / gridDims.w) * this.canvas.width;
        const y = (Math.floor(idx / gridDims.w) / gridDims.h) * this.canvas.height;

        return {x, y};
    }

    getShimmerIdx(x: number, y: number) {
        // X and Y should be unit. 

        const gridDims = this.getGridDims();

        const yIdx = Math.floor( (y / this.canvas.height) * gridDims.h);
        const xIdx = Math.floor( (x / this.canvas.width) * gridDims.w);

        const shimmerIdx = (yIdx * gridDims.w) + xIdx;

        return shimmerIdx;

    }


    onCanvasDimsLoad = () => {
        this.reInitShimmerGrid();
    }


    activateShimmerRegion(x: number, y: number) {

        // We need to activate the tiles within a certain radius of this... 
        /*
            Hm... whats the most effective way to do this? 
            For now, i'll just do a box. 
        */


        const prinaryShimmerIdx = this.getShimmerIdx(x, y);
        const gridDims = this.getGridDims();

        const shimmerGrid: ShimmerGrid = this.sharedState.read() ?? []

        shimmerGrid[prinaryShimmerIdx] = {
            activationT: Date.now(),
            // color: randomColorNoAlpha(),
            color: this.activeColor,
        }

        const radius = 2;
        // ... if we overstep an index... i don't want to wrap though!

        for (let i=-radius; i<radius; i++) {

            for (let j=-radius; j<radius; j++) {

                const innerIdx = prinaryShimmerIdx + i + (gridDims.w * j);

                // Range check
                if (innerIdx < 0 || innerIdx >= shimmerGrid.length) 
                    continue;

                shimmerGrid[innerIdx] = {
                    activationT: Date.now(), 
                    color: this.activeColor,
                }

            }

        }

        // Write to state
        this.sharedState.write(shimmerGrid, SharedStateEntity.FIDGET)

    }


    onClick = (e: PointEvent) =>  {
        this.resetActiveColor();
        this.activateShimmerRegion(e.x, e.y);
    }


    onDrag = (e: PointEvent) => {

        this.activateShimmerRegion(e.x, e.y);

    }



    // Animate frame
    renderFrame = async () => {

        if (!this.ctx)
            return;

        // Render / Update ripples

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const shimmerGrid: ShimmerGrid = this.sharedState.read() ?? []

        shimmerGrid.forEach((shimmerVal, idx) => {

            // Inactive?
            if (shimmerVal === null)
                return;


            // Glow interp
            let interp = Math.sin(1.7 * (Date.now() - shimmerVal.activationT) / 1000);

            // Show square!
            const shimmerLoc = this.getShimmerLoc(idx);

            // canvasUtils.drawCircle(this.ctx, 400, 200, 200, 'rgb(100,255,255');
            if (!this.ctx)
                return;
                
            FidgetUtils.canvas.drawCircle(this.ctx, shimmerLoc.x, shimmerLoc.y, this.shimmerWidth/3, shimmerVal.color, interp);
        });

    }






}