
import FidgetUtils from '../../../Utilities';
import Fidget, { PointEvent, SharedStateEntity } from '../../Core/FidgetClass';
import AyisenMath from '../../../AyisenMath';
import {R2New} from '../../../AyisenMath/R2';
import { Geometry, tesselateGeometry } from '../../../AyisenMath/Geometry';
import Random from '../../../AyisenMath/Random';
import { getCurMotionValueu, MotableDelta } from '../../../AyisenMath/Motion';
import { AyisenELTester } from '../../../AyisenAudio/ela/ela';

// JS Library for Ripple Animations



export interface FullGeo {
    geo: Geometry,

    color: string,

    motableDelta?: MotableDelta,

}


export default class Shatter extends Fidget {

    // Class members
    dragTrigger: boolean = true;

    geos: FullGeo[] = [
        
    ]



    onDrag = (e: PointEvent) => {

    }


    findHovered = (e: PointEvent) => {

        // Check if the point is within the x / y bounds of the geometry

        // ... we've gotta cache some stuff along with these LOL it's gonna get messy to do this

        // - Bounding Box

        // When it comes to hit test... We can draw a line through the point & see how many times it intersets the Geo. 
        // But... have to check "n = num sides"  times.... That's pretty expensive.


    }




    onClick = (e: PointEvent) => {

        if (this.geos.length === 0) {

            this.geos.push(
                { geo: [
                    {x: 0.25 * this.canvas.width, y: 0.25 * this.canvas.height},
                    {x: 0.75 * this.canvas.width, y: 0.25 * this.canvas.height},
                    {x: 0.75 * this.canvas.width, y: 0.75 * this.canvas.height},
                    {x: 0.25 * this.canvas.width, y: 0.75 * this.canvas.height},
                ], color: Random.randomColor()}
            )

        }
        else {

            this.geos = this.geos.flatMap((geo) => {


                // TODO: Hax; we finishh applying the delta right to the geo before moving on 
                let shape = geo.geo
                if (geo.motableDelta?.delta) {
                    shape = shape.map(p => {
                        if (geo.motableDelta?.delta)
                            return R2New.add(p, geo.motableDelta.delta)
                        return p
                    })
                }

                const broken = tesselateGeometry(shape)
                if (broken) {

                    const fullGeos: FullGeo[] = broken.map(e => {return {geo: e, color: Random.randomColor()}})

                    // Pick a drift offset :-)
                    fullGeos.forEach(geo => {
                        const offsetX = Random.getRandomInt(-50, 50)
                        const offsetY = Random.getRandomInt(-50, 50)

                        // Assign a motable delta!
                        geo.motableDelta = {
                            delta: R2New.newVector(offsetX, offsetY),
                            startT: Date.now(),
                            duration: 1000
                        }
                    })

                    return fullGeos
                }
                return [geo]
            })

        }


    }



    // Render Frame
    renderFrame = async () => {

        if (!this.ctx)
            return;

        // Render / Update ripples
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const toRemove: number[] = [];

        // Get meta breath multiplier
        // let metaT =  (Date.now() - this.simulator.initT) / 1000;
        // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
        // metaInterp = (metaInterp + 1) / 2;
        let metaInterp = 1;



        this.geos.forEach((geo) => {

            if (!this.ctx)
                return;

            // TODO: This motion is flawed :-( 
            // - Doesn't update the actual location of the object
            // - If you tesselate during motion; the motion doesn't continue.
            const geoPoints: Geometry = geo.geo.map(p => {
                if (geo.motableDelta) {
                    const offset = getCurMotionValueu(geo.motableDelta)
                    return R2New.add(offset.delta, p)
                }
                return p
            })

            FidgetUtils.canvas.drawGeometry2(
                this.ctx,
                geoPoints, 
                5, 
                geo.color,
                true
            )

        })

        // bubbles.forEach((bubble, idx) => {


        //     // Set current radius
        //     const relT = (Date.now() - bubble.initial.t) / 1000;
        //     let interpVal = Math.sin(2 * Math.PI * (relT / bubble.breathPeriod));
            
        //     // Range : -1 to 1 -> 0 to 1
        //     interpVal = (interpVal + 1) / 2;
        //     interpVal *= metaInterp

        //     // Set Radus : range 0 to maxRad
        //     bubble.radius = bubble.maxRad * (interpVal)

        //     // Set opacity : range 0.2 to 0.8
        //     const opacity = (interpVal * 0.6) + 0.2

        //     if (!this.ctx)
        //         return;

        //     FidgetUtils.canvas.drawBubble(
        //         this.ctx, 
        //         bubble.current.location, 
        //         bubble.radius, 
        //         bubble.current.color, 
        //         opacity
        //     );
        // });

    }

}


