import { Atom, WritableAtom, createStore } from "jotai";


// Global atom store for use
const atomStore = createStore()

// <Value>(atom: Atom<Value>) => Value
export const getAtomValue = atomStore.get
export const setAtomValue = atomStore.set
// export const setValue = (atom: WritableAtom<unknown, [], unknown>) => atomStore.set(atom)

export default atomStore