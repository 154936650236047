

export const quadraticFormula = (a: number, b: number, c: number) => {

    // console.log("A: ", a, ", B: ", b, ", C: ", c);

    let x1 = (-b + Math.sqrt( Math.pow(b, 2) - (4 * a * c))) / (2 * a);
    let x2 = (-b - Math.sqrt( Math.pow(b, 2) - (4 * a * c))) / (2 * a);

    return [x1, x2];

}


export default {
    quadraticFormula,
}