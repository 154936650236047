
import React, { useEffect } from 'react';

import '../../styles/FidgetPage.css';
import MidiKeyboard from '../Keyboards/MidiKeyboard';


export interface FidgetPageProps {
    children: any
}


export default (props: FidgetPageProps) => {

    useEffect(() => {
        console.log("Fidget Page Mount")
    }, [])


    return <div className='fidgetPage'>

        {props.children}
        
    </div>


}