export namespace R1 {

    export interface Range{
        min: number, 
        max: number
    }

    export const getNumberSign = (input: number) => {
        return (input / Math.abs(input));
    }   
    export const interpolate = (x1: number, x2: number, t: number) => {

        // y= mx + b :-)
        const diff = x2 - x1

        if (t > 1)
            return x2
        if (t < 0)
            return x1

        return x1 + (x2 - x1) * t

    }

    export const computeRangeOverlap = (r1: Range, r2: Range) => {
        /**
         * There are a couple cases to keep in mind: 
         * 
         * 1. r1 could be lower bound
         * r1:  --------
         * r2:      ---------
         * 
         * 2. r2 could be lower bound
         * r1:    --------
         * r2:  -------
         * 
         * 3. One could be inside the other
         * r1:  -------------------
         * r2:      ---------
         * 
         * ALSO: The entire thing could be happening in Negative space.
         * 
         * All we really care about though is that we identify the cross over.
         * - Identify the lowest low bound. 
         * - Progress to the other low bound. 
         * - Begin counting 
         * - Exit once we reach either upper bound. 
         * 
         */

        const higherLowBound = Math.max(r1.min, r2.min)
        const lowerHighBound = Math.min(r1.max, r2.max)

        return lowerHighBound - higherLowBound

    }
}