import {el, NodeRepr_t} from '@elemaudio/core';
import { v4 as uuidv4} from 'uuid'
import ela from './ela'
import wavefile, { WaveFile } from 'wavefile';
import { SampleInfo } from './sample/types';

/**
 * Data describing a segment of a sample to be read
 */
export interface SamplePathSegment {
    startLoc: number,   // normalized sample space
    endLoc: number,     // normalized sample space
    pitchShift?: number,

    // Override the lead in silence
    placementPercent?: number,

    // The "duration" of playback should likely be included here

    // Right now... these should be in order, and play with eachother. 
}

/**
 * Data describing a path to read along a sample
 * 
 * NOTES (remove)
 * SamplePathInfo be broken into segments. Those segments should be things that can read in series. 
 */
export interface SamplePath {
    segments: SamplePathSegment[]
}

export interface SampleSegmentData {
    startPercent: number,
    endPercent: number,

    pitchShift?: number,
    leadInSilence?: number,
}


export class SubSampleReader {

    sampleInfo: SampleInfo
    segmentData: SampleSegmentData
    
    // Auto-computed information
    duration: number
    length: number
    counterSampleToStartAt: number | NodeRepr_t
    counter: NodeRepr_t | number
    relativeCounter: number | NodeRepr_t

    // Augmentable constants
    pitchWindowSizeS: number = 0.05

    constructor(sampleInfo: SampleInfo, segmentData: SampleSegmentData, counter?: NodeRepr_t | number) {
        this.sampleInfo = sampleInfo
        this.segmentData = segmentData
        this.counter = counter ?? el.counter(1)

        this.duration = this.sampleInfo.duration * Math.abs(segmentData.endPercent - segmentData.startPercent)
        this.length = this.sampleInfo.length * Math.abs(segmentData.endPercent - segmentData.startPercent)
        this.counterSampleToStartAt = el.ms2samps(segmentData.leadInSilence ?? 0)
        this.relativeCounter = el.sub(this.counter, this.counterSampleToStartAt)

    }

    /**
     * Simplified method for getting a read-path, when pitch is not required
     */
    getReadPath_noPitch() {

        // Simply read through this part of the sample, using the cursor.
        // TODO: Might be nice to have these numbers in a more regular space, and do conversions in & out 
        // (i.e. 0 -> 1, but calling "toSampleSpace" before noting them)

        const path = ela.interp2(this.segmentData.startPercent, this.segmentData.endPercent, this.length,  this.relativeCounter)
        return {path}

    }

    getHann(){

        const hann = (cursor: number | NodeRepr_t, windowLen: number) => {
            return el.pow(
                el.sin(
                    el.div(
                        el.mul(Math.PI, el.mod(cursor, windowLen)), 
                        windowLen, 
                    )
                ),
                2
             )
        }

        // We're going to use this cursor to find the right elements.... 
        return hann(this.relativeCounter, this.length)

    }


    applySurroundingSilence(output: number | NodeRepr_t) {
        return el.mul(
            output,
            this.getPreSilencer(),
            this.getPostSilencer()
        )
    }

    /**
     * Returns an elementary node, which can be be multiplied by the sample-reading output, to silence sounds before this sample should be allowed to play
     */
    getPreSilencer() {

        /**
         * Debugging (remove)
         * Here we're making surue that the counter is AT LEAST GREATER THAN the counter sample we're starting at...
         * that should be fine!
         */

        return el.le(this.counterSampleToStartAt, this.counter)
    }

    /**
     * Returns an elementary node, which can be be multiplied by the sample-reading output, to silence sounds after this sample should be allowed to play
     */
    getPostSilencer() {
        /**
         * Debugging (remove)
         * we're finding the last relevant counter sample to that we want to allow playback at. 
         * we have the counter sample that we're starting at. So we're thinking; what is the 
         */
        const numSamplesThatWillPlay = Math.abs(this.segmentData.endPercent - this.segmentData.startPercent)
        const lastRelevantSample = el.add(this.counterSampleToStartAt, this.sampleInfo.length * numSamplesThatWillPlay)
        return el.ge(lastRelevantSample, this.counter)
    }

    getPlayableNode() {


        // Adjust the segment sample we're refering to if we need to! 
        // TODO @Marcel: HACK ^
        if (this.segmentData.pitchShift) {
            const preComputedSample = this.sampleInfo.pitchedSiblings?.[this.segmentData.pitchShift]
            if (preComputedSample && preComputedSample.channels.length > 0) {
                console.warn("FOUND A PRECOMPUTED SAMPLE TO RUN THROUGH!!!")

                this.sampleInfo = preComputedSample
                console.warn("Sample Info: ", this.sampleInfo)

            }
        }



        // TODO: Simple case if no pitch
        const {path} = this.getReadPath_noPitch()

        // TODO: Clean up all the "helpful info grabbing" into structs / helper functions

        // // TODO: Hmm, can this be stereo? 

        let table: number | NodeRepr_t = el.table({path: this.sampleInfo.channels[0]}, path)
       
        // Apply surrouding silence
        table = this.applySurroundingSilence(table)

        return table

    }


    /**
     * TODO: This class should make it easy to do operations on sub-samples. 
     * 
     * [] -> Methods for converting b/t frames of reference (... i think ideally all math is done in the sub-sample p.o.v and converted to sample frame when building render fns)
     */

}


export class SampleReader {

    sampleInfo: SampleInfo

    constructor(sampleInfo: SampleInfo) {
        this.sampleInfo = sampleInfo
    }

    readSamplePath(path: SamplePath) {

        // Counter will be our crown jewel here. We'll want to use it to progress things. 
        // We'll also use it for any smoothing, envelopes, global offsets, etc. It'll be a global variable. 
        // TODO: Key this!
        const counter = el.counter({key: uuidv4()}, 1)

        // TODO: This relies on the assumption of each segment being back to back. 
        let accumulatedSampleDelay = 0
        const samplesToPlay: (number | NodeRepr_t)[] = path.segments.map((segment, _idx) => {
            // Create the sample 
            const segmentData: SampleSegmentData = {
                startPercent: segment.startLoc,
                endPercent: segment.endLoc,
                pitchShift: segment.pitchShift, 
                leadInSilence: segment.placementPercent !== undefined ? segment.placementPercent * this.sampleInfo.duration * 1000 : accumulatedSampleDelay
            }
            const subSampleReader = new SubSampleReader(this.sampleInfo, segmentData, counter)
            // const playableNode = subSampleReader.getPlayableNodeTestOptimized()
            const playableNode = subSampleReader.getPlayableNode()

            // Accumulate the amount of time this is taking
            accumulatedSampleDelay += subSampleReader.duration * 1000

            return playableNode
            
        })


        return el.add(...samplesToPlay)

    }


}