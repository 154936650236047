
// import canvasUtils from  '../core/canvasUtils';
// import Simulator from '../core/Simulator';
// import { randomColor, getRandomFloat, getRandomInt } from '../core/random';
// import AyisenMath from '../../AyisenMath';

import AyisenMath from '../../../AyisenMath';
import FidgetUtils from '../../../Utilities';
import Fidget, { FidgetConfig, PointEvent } from "../../Core/FidgetClass";
import { R2New } from '../../../AyisenMath/R2';
import { Line } from '../../../AyisenMath/Line';
import { FidgetId } from '../../../../constants/fidgetConsts';

// JS Library for Ripple Animations

let numOscillators = 10;

let oscillatorPeriods: any[] = [];
let oscillatorRanges: any[] = [];
for (let i=0; i<numOscillators; i++) {
    oscillatorRanges.push(AyisenMath.Random.getRandomFloat(Math.PI/16, Math.PI/4));
    oscillatorPeriods.push(AyisenMath.Random.getRandomFloat(15, 45));
}




export default class Tangent extends Fidget {

    currentFoci: R2New.Vector[] = [];

    circleCenter = R2New.newVector(
        this.canvas.width / 2,
        this.canvas.height / 2,
    )

    eccentricPoint = R2New.newVector(
        this.circleCenter.x + 100,
        this.circleCenter.y,
    )

    circleRadX: number = 400;
    circleRadY: number = 400;


    constructor(canvas: HTMLCanvasElement, config: FidgetConfig = {}) {

        super(canvas, FidgetId.TANGENT, config);

    }

    onClick = (e: PointEvent) =>  {

        // Add bubble 
        this.addFoci(e.x, e.y, AyisenMath.Random.getRandomFloat(2, 10), AyisenMath.Random.getRandomInt(50, 100), AyisenMath.Random.randomColor());

    }


    onDrag = (e: PointEvent) => {

        let eccentricPoint = this.transformCenter(this.eccentricPoint);

        if (AyisenMath.R2New.distance(eccentricPoint, R2New.newVector(e.x, e.y)) < 50) {

            this.eccentricPoint = this.transfromCenterOut(R2New.newVector(
                e.x, 
                e.y
            ));

        }
        


    }


    // Bubble management
    addFoci (x: number, y: number, period: number, r: number, color: string) {


        // Create bubble and add to the list 
        const bubble = R2New.newVector(x, y)

        this.currentFoci.push(bubble);

    }


    

    getIntersectionPoints(circleCenter: R2New.Vector, radX: number, radY: number, eccentricPoint: R2New.Vector, theta: number) {

        // TODO @Marcel: Check if both points y is > maxCircY (or min) OR both points x is > maxCircX (or min)

        if (theta === 0)
            theta = 0.0000001;

        // Angle slope 
        let slope = Math.sin(theta) / Math.cos(theta);

        // Line intercept
        let intercept = (-eccentricPoint.x * slope) + eccentricPoint.y;

        let radXSq = Math.pow(radX, 2);
        let radYSq = Math.pow(radY, 2);

        // Compute quadratic params
        let b = ( (-2 * circleCenter.x)/radXSq + (2 * slope * intercept)/radYSq + (-2 * slope * circleCenter.y)/radYSq )
        let a = ( Math.pow(slope, 2)/radYSq + 1/radXSq );
        let c = ( Math.pow(circleCenter.x, 2)/radXSq + Math.pow(intercept, 2)/radYSq - (2 * intercept * circleCenter.y)/radYSq + Math.pow(circleCenter.y, 2)/radYSq - Math.pow(1, 2) );

        // console.log(b);

        // Run Quatratic 
        let solution = AyisenMath.Quadratic.quadraticFormula(a, b, c);

        if (solution.length !== 2)
            return null;

        // Find the the corresponding linear y values 
        let points: R2New.Vector[] = [];

        for (let i = 0; i < 2; i++) {

            let xVal = solution[i];
            let yVal = (slope * xVal + intercept);

            points.push(R2New.newVector(
                xVal, 
                yVal,
            ));

        }

        return points;

    }

    onCanvasDimsLoad = () => {


        this.circleCenter = R2New.newVector(
            this.canvas.width/2,
            this.canvas.height/2,
        )

        this.eccentricPoint = R2New.newVector(
            this.circleCenter.x + 0,
            this.circleCenter.y + 0,
        )

        let limitingDim = this.canvas.width/2;
        if (this.canvas.height < this.canvas.width)
            limitingDim = this.canvas.height/2;

        let circleWidthScale = limitingDim * 0.9;

        this.circleRadX = circleWidthScale;
        this.circleRadY = circleWidthScale;

    }


    computeEccentricLines() {

        let lines = []


        // A line from the eccentric point to the edge of the circle... 
        /*
            Is there a simple way to solve that mathematically? 
        */

        let theta = 0;
        let subDivs = 200;
        while (theta <  2*Math.PI) {

            // Find intersection points!
            let interceptPoints = this.getIntersectionPoints(this.circleCenter, this.circleRadX, this.circleRadY, this.eccentricPoint, theta);

            // console.log(interceptPoints)

            if (interceptPoints !== null && (AyisenMath.R2New.distance(interceptPoints[0], interceptPoints[1]) < 4*Math.max(this.circleRadX, this.circleRadY) ))
                lines.push(interceptPoints);

            // Inc theta
            theta += (2*Math.PI / subDivs);

        }

        return lines;


    }


    transformCenter(point: R2New.Vector) {

        return point;        

    }

    transfromCenterOut(point: R2New.Vector) {

        return point;

    }


    // Animate frame
    renderFrame = async () => {

        if (!this.ctx || !this.initT) 
            return;

        // Render / Update ripples

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const toRemove: number[] = [];

        // Get meta breath multiplier
        let oscillators = [1, 1, 1, 1, 1, 1, 1, 1];
        let oscillatorsOn = false;
        if (oscillatorsOn) {
            for (let i=0; i<numOscillators; i++) {

                let metaT =  (Date.now() - this.initT) / 1000;
                let metaPeriod = 30;
                let metaInterp = Math.sin(2 * Math.PI * metaT / oscillatorPeriods[i]);
                let angleDev = oscillatorRanges[i]* metaInterp;

                oscillators[i] = angleDev;

                // metaInterp = (metaInterp + 1) / 2;
                // let metaInterp = 1;

            }

        }


        const lines = this.computeEccentricLines();

        // console.log(lines)

        // canvasUtils.drawBubble(
        //     this.ctx, 
        //     this.transformCenter(this.circleCenter), 
        //     circleRad, 
        //     'purple',
        //     0.5,
        // );


        for (let pointSet of lines) {

            // Draw normal lines
            FidgetUtils.canvas.drawLine(
                this.ctx, this.transformCenter(pointSet[0]), this.transformCenter(pointSet[1]), 2, 'blue'
            )

            for (let point of pointSet) {

                FidgetUtils.canvas.drawBubble(
                    this.ctx, 
                    this.transformCenter(point),
                    4, 
                    'blue', 
                    0.5
                );

            }

            // Tangent Lines
            let line = [
                this.transformCenter(pointSet[0]),
                this.transformCenter(pointSet[1]),
            ];


            const tangentConfig = [
                {
                    angle: Math.PI/2,
                    oscillator: oscillators[0],
                    color: 'orange',
                },
                {
                    angle: Math.PI/4,
                    oscillator: oscillators[1],
                    color: 'red',
                },
                // {
                //     angle: Math.PI/6,
                //     oscillator: oscillators[2],
                //     color: 'pink',
                // },
                // {
                //     angle: 2*Math.PI/3,
                //     oscillator: oscillators[3],
                //     color: 'green',
                // },
                
                
            ]

            for (let tangentData of tangentConfig) {

                let lineAngle = tangentData.angle * tangentData.oscillator;
                
                // Copy line and rotate
                const tanLine = new Line.PointLine(line[0], line[1])
                tanLine.rotateAboutCenter(lineAngle);
                FidgetUtils.canvas.drawLine(
                    this.ctx,
                    tanLine.p1,
                    tanLine.p2,
                    2, 
                    tangentData.color,
                );

            }


        }


        
        FidgetUtils.canvas.drawBubble(
            this.ctx, 
            this.transformCenter(this.circleCenter), 
            3, 
            'green',
            1,
        );


        // Focus Handler
        FidgetUtils.canvas.drawBubble(
            this.ctx, 
            this.transformCenter(this.eccentricPoint), 
            16, 
            'white',
            0.5,
        );
        FidgetUtils.canvas.drawBubble(
            this.ctx, 
            this.transformCenter(this.eccentricPoint), 
            10, 
            'white',
            0.5,
        );

        FidgetUtils.canvas.drawBubble(
            this.ctx, 
            this.transformCenter(this.eccentricPoint), 
            4, 
            'white',
            0.8,
        );


        // Remove as needed. 
        for (let idx of toRemove)
            this.currentFoci.splice(idx, 1);

    }






}