import ela from "../../../libs/AyisenAudio/ela/ela";
import { AyisenSynth } from "../../../libs/AyisenSynth/AyisenSynth";
import { getAtomValue } from "../../../state/atomStore";
import { samplePathAtom } from "../../../state/sampleState";
import { PlayableNote, getNoteInfo, semitonesBetweenNotes } from "../../../types/audio/note";
import {el, NodeRepr_t} from '@elemaudio/core';

export class SineSynth extends AyisenSynth {

    rerenderAudioFromState() {

        const noteToFreq = getNoteInfo()

        if (this.voices.length === 0) {
            this.renderAudioNodes(el.const({value: 0}))
            return
        }

        function sineTone(t: number|NodeRepr_t) {
            return el.sin(el.mul(2 * Math.PI, t));
          }

        const renderable = el.add(...this.voices.map(voice => {
            const freq = noteToFreq[voice.note.note] ?? 440
            return sineTone(el.phasor(freq, 0)) // TODO: Better behavior; don't just default to 440!
        }))


        // Render!
        this.renderAudioNodes(renderable)

        return renderable


    }


}