import { atom } from "jotai";
import { KitConfig } from "../libs/AyisenAudio/ela/sample/types";
import { SamplePath } from "../libs/AyisenAudio/ela/SampleReader";


export const availableSamplesAtom = atom<{[key: string]: KitConfig}>({})

export const activeSampleAtom = atom<string|null>(null)

export const samplePathAtom = atom<SamplePath>({
    segments: [
        // {startLoc: 0, endLoc: 0.5, pitchShift: key.semiTone},
        // {startLoc: 0, endLoc: 0.75, pitchShift: key.semiTone}

        // {startLoc: 0, endLoc: 0.5, pitchShift: key.semiTone},
        // {startLoc: 1, endLoc: 0, pitchShift: key.semiTone}

        {startLoc: 0, endLoc: 1, },
        {startLoc: 1, endLoc: 0, placementPercent: 0}
    ]
})