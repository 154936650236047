import { atom } from "jotai";


export enum LoadingState {
    LOADING,
    FAILED_TO_LOAD,
    LOADED,
    NONE
}

export const loadingAtom = atom<LoadingState>(LoadingState.NONE)