
import React, { useEffect, useState } from "react"
import { SineSynth } from "./synth"
import { useAtomValue } from "jotai"
import { midiSettingAtom } from "../../../state/midi_keyboard_state"
import MidiKeyboard, { Key } from "../../Keyboards/MidiKeyboard"
import Fidgets from "../../../libs/AyisenFidget/Fidgets";
import { FidgetId } from "../../../constants/fidgetConsts"
import SimCanvas from "../../SimCanvas"

export function Sine() {

    // Shatter synth, new instance on mount
    const [synth, setSynth] = useState<SineSynth|null>()

    const midiSettings = useAtomValue(midiSettingAtom)

    useEffect(() => {
        setSynth(new SineSynth())
    }, [])


    return <>

        <SimCanvas 
            attachNewSimulator = {(canvas: HTMLCanvasElement) => {
                        return new Fidgets.Shatter(canvas, FidgetId.FISSION);
                    }}
            key = {1209287509275}
            fidgetState = {{}}
            setFidgetState = {() => {}}
        />

        {midiSettings.shown && <MidiKeyboard 
            synth={synth ?? undefined}
        />}


    </>
}