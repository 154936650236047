
import Fidget, { PointEvent, SharedStateEntity } from '../../Core/FidgetClass';
import AyisenMath from '../../../AyisenMath';
import FidgetUtils from "../../../Utilities";
import { R2New } from '../../../AyisenMath/R2';
import { FidgetId } from '../../../../constants/fidgetConsts';
import { RippleOnDrag } from '../../EphemeralEngines/engines/RippleOnDrag';

// JS Library for Ripple Animations



export default class Rippler extends Fidget {


    constructor(canvas: HTMLCanvasElement) {

        super(canvas, FidgetId.RIPPLE);

        // Just add the rippling EphemeralEngine :-)
        this.ephemeralEngines.push(new RippleOnDrag())

    }

}