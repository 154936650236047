
import { normalize } from 'path';
import { R2New } from './R2';
import {Line} from './Line';
import Random from './Random';
import { R1 } from './R1';
import {v4 as uuidv4} from 'uuid'

export namespace Bungee {

    // TODO @Marcel: Make this one class, w/ Node
    // TODO @Marcel: Make this interact w/ siblints as IDs? 
    export interface BungeeNode {

        // Positional / Translational
        loc: R2New.Vector,
        velocity: R2New.Vector,

        // Previous location
        prevLoc?: R2New.Vector,
    
        // Connections (ids)
        connections: string[],

        preserveAngleParity?: boolean,
    
    }

    interface BungeeNetwork {[key: string]: BungeeNode};

    export interface Bungee {
        // ID => Node
        nodes: BungeeNetwork,
        color: string
    }

    export const constuctRandomBungees = (center: R2New.Vector = R2New.newVector(0.5, 0.5)) => {
        let bungees: Bungee[] = []
        for (let i=0; i<Random.getRandomInt(2, 3); i++)
            bungees.push(constructRandBungee(center))

        return bungees;
    }

    export const constructRandBungee = (center: R2New.Vector = R2New.newVector(0.5, 0.5)) => {
        const bungee: Bungee = {
            nodes: nGonBungee(Random.getRandomInt(4, 8), center),
            color: Random.randomColor(),
        }
        return bungee;
    }

    // Helpers for construction 
    export const constructTriangle = () => {

        return nGonBungee(3, R2New.newVector(0.5, 0.5));

    }

    export const nGonBungee = (n: number, center: R2New.Vector = R2New.newVector(0.5, 0.5)) => {

        let nodes: BungeeNetwork = {}
        let nodeIdsGenerated: string[] = [] // Ordered list for connection purposes
        const radius = 0.2
        const velocityMax = 0.2

        // For each point... 
        for (let i=0; i<n; i++) {

            const angle = 2 * Math.PI * i / n

            const id = uuidv4()
            nodeIdsGenerated.push()

            // Generate the node
            nodes[id] = {
                loc: R2New.newVector(
                    center.x + radius * Math.cos(angle),
                    center.y + radius * Math.sin(angle),
                ),
                velocity: R2New.newVector(Random.getRandomFloat(-velocityMax, velocityMax), Random.getRandomFloat(-velocityMax, velocityMax)),
                // velocity: {x: 0, y: 0},
                connections: [],
                preserveAngleParity: true,
            }

            // Connect to the node behind
            if (i !== 0) {
                nodes[i].connections.push(nodeIdsGenerated[i-1])
                nodes[i-1].connections.push(nodeIdsGenerated[i])
            }
        }

        // Connect the front node to the node behind? 
        if (n > 1) {

            const firstNodeId = nodeIdsGenerated[0]
            const lastNodeId = nodeIdsGenerated[nodeIdsGenerated.length - 1]

            nodes[firstNodeId].connections.push(lastNodeId)
            nodes[lastNodeId].connections.push(firstNodeId)
        }

        return nodes;

    }

    export const getParityAngle = (vertex: R2New.Vector, c1: R2New.Vector, c2: R2New.Vector) => {

        // Get the angle: V-C1 to V-C2
        let vToC1 = R2New.copy(c1);
        vToC1 = R2New.sub(vToC1, vertex);
        vToC1 = R2New.norm(vToC1).norm;

        let vToC2 = R2New.copy(c2);
        vToC2 = R2New.sub(vToC2, vertex);
        vToC2 = R2New.norm(vToC2).norm;

        const angle1FromX = Math.acos(vToC1.x);
        const angle2FromX = Math.acos(vToC2.x);
        return (angle2FromX - angle1FromX);

    }

    export const getHydratedConnections = (node: BungeeNode, bungee: Bungee) => {
        return node.connections.map(connectionId => {
            return bungee.nodes[connectionId]
        })
    }

    export const checkForAngleParityViolation = (node: BungeeNode, bungee: Bungee) => {

        // TODO: This function is pretty obsolete.

        // Check if the angle parity of the connected nodes is changing. 
        // Prevent it!

        if (!node.preserveAngleParity)
            return;

        // For now, only support 2 connection
        if (node.connections.length !== 2)
            return;

        const connections = getHydratedConnections(node, bungee)

        if (!node.prevLoc || !connections[0].prevLoc || !connections[1].prevLoc)
            return;

        // Initial angle between & final angle between
        const prevAngle = getParityAngle(node.prevLoc, connections[0].prevLoc, connections[1].prevLoc);
        const angle = getParityAngle(node.loc, connections[0].loc, connections[1].loc)

        // Check if the angle changed. 
        /**
         * This will be the case if :: 
         * - Angle switches from negative to positive (vice versa)
         * - C2 goes from being greater, to crossing 2PI/0, AND being greater
         * 
         * To detect the second,,... we need to have some awareness of the crossing of 0. 
         * Interestingly... sampling rate dictates that I CAN'T know for sure if 0 was crossed or not. 
         * Not without trajectory... 
         * If I track the previous velocity,.. I can know which direction this node moved in. 
         * 
         * ACTUALY, these things are moving linearly at any given jump... 
         * 
         * AH.... but they can cross the vertex, or move the other way. 
         * 
         * So... this might not be fully solvable without the velocity aware? 
         * 
         * Let me just solve for the other case first...
         */

        // console.log("Prev Angle: ", prevAngle);
        // console.log("Angle: ", angle);

        if (R1.getNumberSign(prevAngle) !== R1.getNumberSign(angle)) {
            console.log("PARITY VIOLATION DETECTED")
            connections[0].velocity.x *= -1;
            connections[0].velocity.y *= -1;
            connections[1].velocity.x *= -1;
            connections[1].velocity.y *= -1;
            connections[0].loc = {...connections[0].prevLoc}
            connections[1].loc = {...connections[1].prevLoc}
        }

        // TODO @Marcel: For this to work properly.. I also need to prevent points from crossing the center of the Bungee!!!    
        // They should not be allowed to CROSS any of the other lines. 

        // ... that's an interesting requirement. 

        // I'll need to check the vector 
        
        return;


    }

    export const checkForBoundViolations = (vertex: BungeeNode, bungee: Bungee, ignoreIdx: number|undefined) => {

        if (!vertex.prevLoc)
            return;

        // Get the vector from past to future. 
        let trajectory = R2New.copy(vertex.loc);
        trajectory = R2New.sub(trajectory, vertex.prevLoc);

        // For each line in the object... lol
        // NOTE: This is kinda hacky. We should be recursing over all the connections. 
        // TODO @Marcel: Fix that. Might break now that were storing connections by id.
        return;
        // for (let i=0; i<bungee.nodes.length; i++) {

        //     const idx1 = i;
        //     const idx2 = (i+1) % bungee.nodes.length;

        //     const node1 = bungee.nodes[idx1]
        //     const node2 = bungee.nodes[idx2]
        //     if (!node1.prevLoc || !node2.prevLoc)
        //         continue;

        //     if (ignoreIdx !== undefined && (ignoreIdx === idx1 || ignoreIdx ===(idx2)))
        //         continue

        //     // Check for the line forward
        //     let boundingLine = R2New.copy(bungee.nodes[idx2].loc);
        //     boundingLine = R2New.sub(boundingLine, bungee.nodes[idx1].loc);

        //     // Check for intersection
        //     const trajectoryLine = new Line.PointLine(vertex.loc, vertex.prevLoc);
        //     const curBoundaryLine = new Line.PointLine(bungee.nodes[idx2].loc, bungee.nodes[idx1].loc);
        //     const pastBoundaryLine = new Line.PointLine(node1.prevLoc, node2.prevLoc);
        //     if (trajectoryLine.doesIntersect(curBoundaryLine) ||
        //         trajectoryLine.doesIntersect(pastBoundaryLine)
        //     ) {

        //         // Get the normalized version of the line we're bouncing off of. 
        //         let lineVec = R2New.copy(node1.loc);
        //         lineVec = R2New.sub(lineVec, node2.loc)
        //         lineVec = R2New.norm(lineVec).norm
        //         // const lineVec = R2.normalize(R2.subtract(node1.loc, node2.loc)).norm;
        //         const orthoVec = R2New.orthogonal(lineVec);

        //         // Find the dot product of the orthoVec, and the lineVec
        //         const dotProd = R2New.dot(vertex.velocity, orthoVec);
                
        //         // 
        //         const velocityUpdate = R2New.fromNormAndMag(orthoVec, dotProd * -2 * R2New.norm(vertex.velocity).mag);
            
        //         // Proper solution: Reflect velocity off the line
        //         // console.log("Dot Prod: ", dotProd);
        //         // console.log("Velocity Update: ", velocityUpdate);
        //         // console.log("")
        //         // console.log("V Before: ", vertex.velocity)
        //         // vertex.velocity = R2.add(vertex.velocity, velocityUpdate);
        //         // console.log("V After: ", vertex.velocity);
        //         // vertex.loc = {...vertex.prevLoc};
        //         // for (let j=0; j<1; j++)
        //         //     vertex.loc = R2.add(vertex.loc, vertex.velocity)
        //         // console.log("CROSS")

        //         // Simplified: Reverse everythings velocity
        //         vertex.loc = {...vertex.prevLoc};
        //         vertex.velocity = R2New.reverse(vertex.velocity);
        //         node1.loc = {...node1.prevLoc};
        //         node1.velocity = R2New.reverse(node1.velocity);
        //         node2.loc = {...node2.prevLoc};
        //         node2.velocity = R2New.reverse(node2.velocity);


        //         return;

        //     }

        // }

        /**
         * 
         * Reflect off the line itself. 
         * 
         * To do this, we want to reverse only the component of the velocity ORTHOGONAL to the line. 
         * 
         * I can find the normal vector orthogonal to the line... 
         * 
         * Then i need to find how much of my vector lies along that line... 
         * 
         * I can do a dot product i guess? And then I can do what? Reverse only that percent? No, i don't want to reverse any of certain directions. 
         * 
         * But I think what I can do is add the orthogonal, with that proportion of the magnitude of the original line. 
         */

        /**
         * 
         * There's a bug here.... 
         * 
         * I think it's that the lines are moving... 
         * We may interesct the past OR future line. 
         * 
         * OR... it crosses twice in a row, and thus does a double bounce.... 
         * 
         * That is, it crosses a calculated even number of times, since the line and the point are both moving... 
         * 
         * 
         * 
         */


    }

    



}

// export interfac