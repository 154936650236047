import { R2New } from "../AyisenMath/R2";
import { Circle } from "./Circle";
import { CollisionResult, Obj2DType, Object2D } from "./Object";
import { Polygon } from "./Polygon";
import { Scene } from "./Scene";


/**
 * NOTES (delete)
 * 
 * I'm wondering how this reflow might work.... 
 * We have multiple object types that we need to reflow in different ways... so we need to check. 
 * 
 * I don't want to pass in a list of all pollygons.... but I want to be able to render them and find collisions. 
 * Maybe I need a "Scene" object? 
 * 
 * With a scene.... I still need to pass the object that we are reflowing around.
 * 
 * And i need to know the type of the object. The type of object... hmmm... 
 * I could provide a uuid for the object... but then the typing also needs to work well.... 
 * 
 * I would we're going to check for collision with any other things in the scene.... 
 * 
 * Maybe I need to have OBJECT do something.... like each object is able to perform some collision check with any other? 
 * Nah... this is a pain though, there's a tad of complexity i'd rather not have. But maybe can't avoid.  
 * 
 * The only hitch is that circle<->circle collisions don't use projections. So they can't be generic off the normals of the other objs.
 * 
 */

export class CollisionResolver {

    // TODO: Expand to include other obj collisions (like circles)
    // Collision consequences for polygon at index
    static checkForAndReflowCollisions(obj: Object2D, scene: Scene, blockList: string[] = []) {

        const effectedIds: string[] = []

        for (let id of Object.keys(scene)) {
            if (id === obj.id) continue;
            if (blockList.includes(id)) continue;

            const otherObj = scene[id]

            let collision: CollisionResult|null = null

            // Case :: polygon <-> polygon
            if (obj instanceof Polygon && otherObj instanceof Polygon) collision = obj.detectCollision(otherObj)

            // Case :: polygon <-> circle
            if (obj instanceof Polygon && otherObj instanceof Circle) collision = obj.detectCircleCollision(otherObj)

            // Case :: circle <-> polygon
            if (obj instanceof Circle && otherObj instanceof Polygon) collision = otherObj.detectCircleCollision(obj)

            // Case :: circle <-> circle
            if (obj instanceof Circle && otherObj instanceof Circle) collision = obj.detectCircleCollision(otherObj)

            if (collision) {
                // Want to push 'other' along the normal dir that's aligned with hoveredCenter->otherCenter
                const hoverCenterToOtherCenter = R2New.sub(otherObj.computeCenter(), obj.computeCenter())
                const sameDirAsNormal:boolean = R2New.dot(hoverCenterToOtherCenter, collision.axis) > 0 ? true : false
                const axisToPushAlong = sameDirAsNormal ? collision.axis : R2New.mul(collision.axis, -1)

                // push the other out of the way by the relevant amount along the axis! 
                otherObj.move(R2New.mul(axisToPushAlong, collision.depth))
                effectedIds.push(id)
            }
        }

        // Recurse down touched objects 
        // BUG: I thnk there's something odd going on where we can deep recurse when 2 things in this list effect eachother? Might be worth considering.
        for (let effectedId of effectedIds) {
            // Avoid infinite recurrsion by blocklisting the item we just moved from being moved by targets :-)
            CollisionResolver.checkForAndReflowCollisions(scene[effectedId], scene, [...blockList, obj.id])
        }
            
        

    } 
    

}