
import * as THREE from 'three'

export function getRandomInt(min: number, max: number) {
    const range = max - min;
    return Math.floor(min + Math.random() * Math.floor(range));
}

export function randomChoice(array: any[]) {
    return array[getRandomInt(0, array.length)]
}

export function getRandomFloat(min: number, max: number) {
    const range = max - min;
    return min + Math.random() * range;
}

export const randomColor = () => {

    let str = "rgba(";

    for (let i=0; i<3; i++)
        str += getRandomInt(0, 255) + ',';

    str += getRandomFloat(0.3, 1.0) + ")";

    return str;


}

export const randomBool = (likelihood = 0.5) => {

    return (Math.random() < likelihood);

}


export const randomColorNoAlpha = () => {

    let str = "rgb(";

    for (let i=0; i<2; i++)
        str += getRandomInt(0, 255) + ',';

    str += getRandomInt(0, 255) + ")";

    return str;

}

export const randomThreeColor = () => {

    const color: THREE.ColorRepresentation = new THREE.Color(getRandomFloat(0, 1), getRandomFloat(0, 1), getRandomFloat(0, 1))
    return color;

}



export default {

    getRandomInt,
    getRandomFloat,
    randomBool,
    randomColorNoAlpha,
    randomColor,
    randomThreeColor,
    randomChoice,

}