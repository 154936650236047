import ela from "../../../libs/AyisenAudio/ela/ela";
import { AyisenSynth } from "../../../libs/AyisenSynth/AyisenSynth";
import { getAtomValue } from "../../../state/atomStore";
import { samplePathAtom } from "../../../state/sampleState";
import { PlayableNote, semitonesBetweenNotes } from "../../../types/audio/note";
import {el, NodeRepr_t} from '@elemaudio/core';


// This is the note that we assume the base sample plays when you hit. We shift around this
const BASE_NOTE_SHIFTED_FROM: PlayableNote = "C4"

export class ShatterSynth extends AyisenSynth {

    rerenderAudioFromState() {
        // override: render from state

        console.warn("Rendering Shatter from voices!~", this.voices)

        // Sample atom
        const sample = this.getActiveSample()
        if (!sample) return null

        // TODO: THERE'S A BUG WHERE IF THE LIST IS EMPTY, WE DON'T CORRECTLY RENDER TO NOTHING. this line hackily fixes that.
        if (this.voices.length === 0) {
            this.renderAudioNodes(el.const({value: 0}))
        }

        // Paths atom
        const samplePath = getAtomValue(samplePathAtom)

        // We need to create a path for each voice! 
        const voicePaths = this.voices.map((voice, idx) => {

            // Figure out how many semi tones off we should shift.... 
            // TODO: better handling for the bad case :: defaulting to undefined here menas that either 0 semitones, or not-found -> render the C4 note
            let semiTones = semitonesBetweenNotes(BASE_NOTE_SHIFTED_FROM, voice.note.note) ?? undefined

            const path = samplePath
            path.segments = path.segments.map(e => {
                return {...e, pitchShift: semiTones}
            })

            return {...path}
        })

        // Get renderable
        const renderable = ela.pathsAlongSample(sample, voicePaths)

        // Render!
        this.renderAudioNodes(renderable)

        return renderable


    }


}