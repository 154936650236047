
import WebRenderer from '@elemaudio/web-renderer';
import {el} from '@elemaudio/core';
import { v4 as uuidv4 } from 'uuid';

import AyisenMath from '../../AyisenMath';
import { LoadingSampleBuffer } from '../audio_analysis/LoadingSampleBuffer';
import { KitConfig, LoadedKitInfo, SampleInfo } from '../ela/sample/types';
import { getAtomValue, setAtomValue } from '../../../state/atomStore';
import { activeSampleAtom, availableSamplesAtom } from '../../../state/sampleState';
import { LoadingState, loadingAtom } from '../../../state/loading';


const getAyisenKitConfig: () => Promise<{[key: string]: string}> = async () => {

    const res = await fetch("resources/Samples/Ayisen-Kit-1/config.json");
    const value = await res.json();
    return value

}


interface SampleLoadQueueEntry {

    // Main data
    data: LoadingSampleBuffer
    
    // Meta data for loading
    kitName: string
    sampleKey: string // used to load all pitched siblings 
    pitchSemiTone: number // from base pitch

    // Temporary load-time data 


}



export default class SampleHandler {

    loadedKits: {[key: string]: LoadedKitInfo} = {}

    sampleLoadQueue: SampleLoadQueueEntry[] = []

    ctx: AudioContext;
    core: WebRenderer;

    constructor (_audioCtx: AudioContext, _audioCore: WebRenderer) {

        this.ctx = _audioCtx;
        this.core = _audioCore;

        this.loadAyienKit();

        

    }



    audioBufferToLoadable = (sampleBuffer: AudioBuffer, sampleName: string, kitFileLoadMap: {[key: string]: Float32Array}, loadedKitInfo: LoadedKitInfo, alsoPitch = false) => {

        // Num channels 
        if (sampleBuffer.numberOfChannels === 1) {

            console.warn("------- DOING THE GOOD NEW LOADING -------")

            const loadingBuffer = LoadingSampleBuffer.fromAudioBuffer(sampleBuffer, sampleName)

            // Load em up 
            const kitInfo = loadingBuffer.addToKitLoader(kitFileLoadMap, loadedKitInfo)


            if (alsoPitch) {
                for (let semiTone=-12; semiTone<=12; semiTone ++) {

                    // Derive 
                    const pitchedLoadingBuffer = loadingBuffer.createPitchedDecendant(semiTone)

                    // Load em up 
                    const pitchedKitInfo = pitchedLoadingBuffer.addToKitLoader(kitFileLoadMap, loadedKitInfo)

                    // Attach 
                    const siblings = kitInfo.pitchedSiblings ?? {}
                    siblings[semiTone] = pitchedKitInfo
                    kitInfo.pitchedSiblings = siblings

                }
            }


        }
        else if (sampleBuffer.numberOfChannels === 2) {

            loadedKitInfo[sampleName] = {
                channels: [],
                duration: sampleBuffer.duration,
                length: sampleBuffer.length,
                nativeSampleRate: sampleBuffer.sampleRate,
                buffers: []
            }

            for (let i=0; i<2; i++) {
                const virtualFileName = sampleName + "_" + i;
                // Set data for loading
                const buffer = sampleBuffer.getChannelData(i)
                kitFileLoadMap[virtualFileName] = buffer;
                // Catalog virtual file names for our loaded kit info
                loadedKitInfo[sampleName].channels.push(virtualFileName)
                
                const buffers = loadedKitInfo[sampleName].buffers ?? []
                buffers.push(buffer)
                loadedKitInfo[sampleName].buffers = buffers
            }
        }

    }


    /**
     * Process a kit into state. 
     * Don't load any audio data though.
     * @param kitConfig 
     */
    loadKitToData = async(kitName: string, kitConfig: KitConfig) => {
        const value = getAtomValue(availableSamplesAtom)
        value[kitName] = kitConfig
        setAtomValue(availableSamplesAtom, value)
    }

    /**
     * Load a sample from kit to be able to playback 
     * TODO: Should have a higher level fn that also cleans up the previous. 
     */
    loadSampleForPlayback = async (sampleName: string, samplePath: string, kitName: string, alsoPitch: boolean) => {

        // Grab the sample 
        const sampleBuffer = await this.getBufferFromPath(samplePath)

        // Grab the 
        return this.loadSampleBuffer(sampleBuffer, sampleName, kitName, alsoPitch)

    }

    /**
     * Load a sample to an audio buffer
     */
    getBufferFromPath = async (samplePath: string) => {
        // Load the sample in 
        let res = await fetch(samplePath);
        let sampleBuffer = await this.ctx.decodeAudioData(await res.arrayBuffer());
        return sampleBuffer
    }


    queueSampleLoad = (queueEntry: SampleLoadQueueEntry) => {
        this.sampleLoadQueue.push(queueEntry)
    }

    finishLoadingSampleQueue = () => {

        // Initialize what we need 
        let elementaryVFSLoadPayload: {[key: string]: Float32Array} = {};
        let loadedKitInfo: LoadedKitInfo = {};

        // We're going to need to tag the "SampleInfo" siblings on the tonic, to get this to work right at the moment.... 
        // This is silly long term, but gets me where i'm going for now.

        // To do this; we need to identify the tonic of each sample.... perhaps an op to weave these after the fact. Should be a single pass. 
        // We need to link the adjacent "sampleInfo", so that's the way. 

        // TODO: (P1) right now, everything will just load to the first kit noted. should be able to load to different. 
        const kitName = 'Demo Kit (todo)'


        // 1: Identify the tonics, and add them to the load config + vfs
        this.sampleLoadQueue.forEach((elem) => {

            // Check if tonic 
            if (elem.pitchSemiTone === 0) {

                // Load kit 
                loadedKitInfo[elem.sampleKey] = elem.data.toSampleInfo()

                // Load vfs
                elementaryVFSLoadPayload[elem.sampleKey] = elem.data.buffer

            }


        })

        // 2: Idenfity the non-tonics, and attach them to the tonics + vsf
        // : remember to augment sample name first to be unique (pitch extension)

        this.sampleLoadQueue.forEach((elem) => {

            if (elem.pitchSemiTone !== 0) {

                // Find the related tonic 
                const tonic = loadedKitInfo[elem.sampleKey] // NOTE: kinda messy

                if (tonic) {

                    // Augment sample name 
                    const vfsName =  elem.sampleKey + "-" + elem.pitchSemiTone
                    elem.data.sampleName = vfsName

                    // Load kit (attach to tonic)
                    const pitchedSiblings = tonic.pitchedSiblings ?? {}
                    pitchedSiblings[elem.pitchSemiTone] = elem.data.toSampleInfo()
                    tonic.pitchedSiblings = pitchedSiblings

                    // Load vfs 
                    elementaryVFSLoadPayload[vfsName] = elem.data.buffer

                }

            }

        })

        // TODO @Marcel (P1): This kit should be able to coallesece into existing.

        this.core.updateVirtualFileSystem(elementaryVFSLoadPayload);

        this.loadedKits[kitName] = loadedKitInfo;

        console.warn("VFS: ", elementaryVFSLoadPayload)
        console.warn('Loaded Kits: ', this.loadedKits)

        // TODO: This "kit state" likely needs to be an atom of its own. So that kit alterations are captured on their own. 

    }

    abortLoadingSampleQueue = () => {
        this.sampleLoadQueue = []
    }



    loadSampleBuffer = (sampleBuffer: AudioBuffer, sampleName: string, kitName: string = "User", alsoPitch = false) => {

        
        if (sampleBuffer.numberOfChannels === 1) {

            console.warn("------- DOING THE GOOD NEW LOADING -------")

            const loadingBuffer = LoadingSampleBuffer.fromAudioBuffer(sampleBuffer, sampleName)

            this.queueSampleLoad({
                data: loadingBuffer, 
                kitName,
                sampleKey: sampleName,
                pitchSemiTone: 0
            })

            if (alsoPitch) {
                for (let semiTone=-12; semiTone<=12; semiTone ++) {

                    // Already did 0 :-)
                    if (semiTone === 0) continue

                    // Derive 
                    const pitchedLoadingBuffer = loadingBuffer.createPitchedDecendant(semiTone)

                    this.queueSampleLoad({
                        data: pitchedLoadingBuffer, 
                        kitName,
                        sampleKey: sampleName,
                        pitchSemiTone: semiTone
                    })

                }
            }

        }
        else {
            console.warn(`Not loading sample ${sampleName}, multi-channel sample support incoming`)
            return false
        }
        // TODO @Marcel: Support multi-channel (via LoadingSampleBuffer)
        // else if (sampleBuffer.numberOfChannels === 2) {

        //     loadedKitInfo[sampleName] = {
        //         channels: [],
        //         duration: sampleBuffer.duration,
        //         length: sampleBuffer.length,
        //         nativeSampleRate: sampleBuffer.sampleRate,
        //         buffers: []
        //     }

        //     for (let i=0; i<2; i++) {
        //         const virtualFileName = sampleName + "_" + i;
        //         // Set data for loading
        //         const buffer = sampleBuffer.getChannelData(i)
        //         kitFileLoadMap[virtualFileName] = buffer;
        //         // Catalog virtual file names for our loaded kit info
        //         loadedKitInfo[sampleName].channels.push(virtualFileName)
                
        //         const buffers = loadedKitInfo[sampleName].buffers ?? []
        //         buffers.push(buffer)
        //         loadedKitInfo[sampleName].buffers = buffers
        //     }
        // }

        this.finishLoadingSampleQueue()
        return true

    }


    /**
     * DEPRECATED
     */
    loadKitWithName = async (name: string, kitConfig: KitConfig) => {

        // Set config 
        // TODO: Catalogs 
        // this.loadedKits[name] = kitConfig;

        let kitFileLoadMap: {[key: string]: Float32Array} = {};
        let loadedKitInfo: LoadedKitInfo = {};

        await Promise.all(Object.keys(kitConfig).map( async (sampleName) => {
            
            const sampleBuffer = await this.getBufferFromPath(kitConfig[sampleName])

            // Helper to compute via an audioBuffer
            this.audioBufferToLoadable(sampleBuffer, sampleName, kitFileLoadMap, loadedKitInfo, sampleName.includes('Banjo 1.wav'))
            
        }))

        console.log("Loaded Kit Info: ", loadedKitInfo);
        // for (let sampleName of Object.keys(kitConfig)) {

            

        // }

        // const kitFileLoadMap = Object.fromEntries(await Promise.all(Object.keys(kitConfig).map( async virtualFName => {
        //     let res = await fetch(kitConfig[virtualFName]);
        //     let sampleBuffer = await this.ctx.decodeAudioData(await res.arrayBuffer());
        //     // TODO @Marcel: Elementary only supports single channel. 
        //     // I should use this number of channels attribut to package my samples into smoething a little more sophisticated... 
        //     // They can have a structure such that 
        //     /**
        //      * {fileKey: 
        //      *  
        //      * }
        //      */
        //     // sampleBuffer.numberOfChannels
        //     return [virtualFName, sampleBuffer?.getChannelData(0)]
        // })))

        this.core.updateVirtualFileSystem(kitFileLoadMap);

        this.loadedKits[name] = loadedKitInfo;

    }


    loadAyienKit = async () => {

        const kitConfig = await getAyisenKitConfig();


        // Load the kit to be viewable 
        this.loadKitToData("Ayisen", kitConfig)

        // NOTE: Loading a sample by default is.... expensive! It causes major main thread delays! 
        // const sampleName = 'Ayisen-Kit-1/Banjo 1.wav' // TODO: Don't hard code
        // this.loadSpecificSampleName(sampleName)


    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    loadSpecificSampleName = async (sampleName: string) => {

        const kitConfig = await getAyisenKitConfig();

        // Set loading 
        setAtomValue(loadingAtom, LoadingState.LOADING)


        // HACK: Sleep so that react can render updated loading state before we move on.
        await this.sleep(10)

        // Load 1 sample to be playable (pitched)
        const samplePath = kitConfig[sampleName]
        const success = await this.loadSampleForPlayback(sampleName, samplePath, "Ayisen", true)

        // Apply as active sample 
        if (success) {
            setAtomValue(activeSampleAtom, sampleName)
            setAtomValue(loadingAtom, LoadingState.LOADED)
        }
        else {
            setAtomValue(loadingAtom, LoadingState.FAILED_TO_LOAD)
        }

    }

    getRandomAyisenKitSample = () => {

        console.log('Loaded Kits Found: ', this.loadedKits)

        if (!("Ayisen" in this.loadedKits))
            return null;

        const allSampleNames = Object.keys(this.loadedKits["Ayisen"])

        const randomSampleName = allSampleNames[AyisenMath.Random.getRandomInt(0, allSampleNames.length-1)];

        return this.loadedKits["Ayisen"][randomSampleName];

    }

    

}

export const sampleInfoToSignal = (sample: SampleInfo) => {
    return el.add(...sample.channels.map((channelFileName, idx) => {
        return el.sample({path: channelFileName, key: uuidv4()}, 1, 1);
    }))
}
