
import { R2New } from "./R2";

export namespace Line {

    export class PointLine {

        // Properties 
        p1: R2New.Vector;
        p2: R2New.Vector;

        constructor(p1: R2New.Vector, p2: R2New.Vector) {

            this.p1 = p1;
            this.p2 = p2;

        }

        // Rotate 
        rotateAboutCenter = (theta: number) => {

            // Find the center of the points.
            const center = R2New.findPointsCenter([this.p1, this.p2]);
        
            // Rotate each point about center
            const newPoints = R2New.rotate2D(center, theta, [this.p1, this.p2]);

            // Assign vals
            this.p1 = newPoints[0];
            this.p2 = newPoints[1];
        
            return newPoints;
        
        }

        getXBounds = () => {

            let minX = null;
            let maxX = null;

            let points = [this.p1, this.p2];
        
            for (let i=0; i<points.length; i++) {
                if (!minX || points[i].x < minX)
                    minX = points[i].x;
                if (!maxX || points[i].x > maxX)
                    maxX = points[i].x;
            }
        
            if (minX === null || maxX === null)
                return null;
        
            return {
                min: minX, 
                max: maxX,
            }
        
        }

        toParameterizedLine = () => {

            const m = (this.p1.y - this.p2.y) / (this.p1.x - this.p2.x);
            const b =  this.p1.y + (m * -this.p1.x);

            return new ParameterizedLine(m, b);

        }

        doesIntersect = (line: PointLine) => {

            // Parameterize vectors as lines
            const line1Param = this.toParameterizedLine();
            const line2Param = line.toParameterizedLine();

            if (!line1Param || ! line2Param)
                return false;

            // Parallel? 
            if (Math.abs(line1Param.m - line2Param.m) < 0.000001)
                return false;

            // Find the intersection of these two... where y = y
            const xSolution = (line2Param.b - line1Param.b) / (line1Param.m - line2Param.m);
            
            // Make sure the x solution is within the x range of each vector!
            const line1XBounds = this.getXBounds();
            const line2XBounds = line.getXBounds();

            if (line1XBounds === null || line2XBounds === null)
                return false;

            if (line1XBounds.min < xSolution && xSolution < line1XBounds.max && line2XBounds.min < xSolution && xSolution < line2XBounds.max) {
                // console.log("X Solution: ", xSolution)
                // console.log("Line 1 bounds: ", line1XBounds)
                // console.log("Line 2 bounds: ", line2XBounds);
                return true;
            }

            return false;


        }



    }

    export class ParameterizedLine {

        // Properties
        m: number;
        b: number;

        constructor(m: number, b: number) {

            this.m = m;
            this.b = b;

        }

        
    }


}



export default {
    Line,
}