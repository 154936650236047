import {el, NodeRepr_t} from '@elemaudio/core';


const utilFunctions = {

    interp: (startVal: number | NodeRepr_t, targetVal: number | NodeRepr_t, duration: number| NodeRepr_t) => {

        // Displacement from start -> target
        const m = el.sub(targetVal, startVal);

        // Interpolate across the diff from start -> target over the course of the duration
        const phasor = el.phasor(el.div(1, duration), 0)
        const mx = el.mul(
            m, 
            phasor
        )

        // Return a motion, from start to target.
        return {value: el.add(
            startVal, 
            mx
        ), phasor};

    },

    interp2: (startVal: number, targetVal: number, duration: number| NodeRepr_t, x: number | NodeRepr_t) => {

        // Displacement from start -> target
        const m = el.div(el.sub(targetVal, startVal), duration);

        // Interpolate across the diff from start -> target over the course of the duration
        const y = el.add(
            el.mul(m, x),
            startVal
        )
        return y;

    }
}

export default utilFunctions