

import ayisenRandom from './Random';
import { R2New } from './R2';



export interface GraphNode {

    uid: string,
    loc: R2New.Vector,
    velocity: R2New.Vector,

}

export interface GraphNodeMap {
    [key: string] : GraphNode,
}


export interface GraphConnection {

    time: number,
    decay: number,


}

export interface GraphConnectionMap {
    [key: string] : {
        [key: string] : GraphConnection,
    }
}



export const generateRandomNodes = (lowerNodeBound = 30, upperNodeBound = 100) => {

    let numNodes = ayisenRandom.getRandomInt(lowerNodeBound, upperNodeBound);

    let nodes: GraphNodeMap = {};

    for (let i=0; i<numNodes; i++) {

        const uid = ("n"+i);

        // Pick x and y
        nodes[uid] = {
            uid,
            loc: R2New.newVector(
                ayisenRandom.getRandomFloat(0, 1),
                ayisenRandom.getRandomFloat(0, 1),
            ),
            // width:,
            velocity: R2New.newVector(
                ayisenRandom.getRandomFloat(0, 0.15),
                ayisenRandom.getRandomFloat(0, 0.15),
            )
        };

    }

    return nodes;
    
}


export const addConnection = (uid1: string, uid2: string, connectionGraph: GraphConnectionMap) => {

    // Init connections if needed
    for (let val of [uid1, uid2]) {
        if (!(val in connectionGraph))
            connectionGraph[val] = {};
    }

    // Make sure connection not present
    if (uid2 in connectionGraph[uid1]) {
        return;
    }

    // Connect
    const connectionStats: GraphConnection = {

        // Time metric
        time: 0.5,

        // Delay
        decay: 0.8,

    }

    connectionGraph[uid1][uid2] = {...connectionStats};
    connectionGraph[uid2][uid1] = {...connectionStats};

}

export const generateRandomConnections = (nodes: GraphNodeMap) => {

    // Pick connections, with a density metric. 
    
    const connectionGraph: GraphConnectionMap = {}

    // For each node... 
    for (let nodeId of Object.keys(nodes)) {

        let node = nodes[nodeId];

        // Init if needed 
        if (!(node.uid in connectionGraph)) {
            connectionGraph[node.uid] = {};
        }


        // For all the other nodes 
        for (let connectionId of Object.keys(nodes)) {

            if (nodeId === connectionId)
                continue;

            let connection = nodes[connectionId];

            // Dist 
            const distance = R2New.distance(node.loc, connection.loc);

            // Probability function
            // const likelihood =

            const connected = ayisenRandom.randomBool(0.2 / 2);

            if (connected) {

                addConnection(node.uid, connection.uid, connectionGraph);

            }

        }

    }

    return connectionGraph;

}



export default {
    generateRandomNodes,
    addConnection, 
    generateRandomConnections,
}