import { WaveFile } from "wavefile"
import { LoadedKitInfo, SampleInfo } from "../ela/sample/types"
import AyisenMath from "../../AyisenMath"




/**
 * TODO (clean up): Add these to an obj that makes these operations simpler
 * TODO (testing): Write unit tests for what each of these needs to do
 */
const writeBufferToOtherBuffer = (toInsert: Float32Array, toInsertTo: Float32Array, startPos: number) => {

    // console.warn("Insert op from ", startPos, " to ", startPos + toInsert.length)
    for (let i=0; i<toInsert.length; i++) {

        const val = toInsert[i]
        toInsertTo[startPos + i] = toInsertTo[startPos + i] + val

    }

}

const buffer_mul_by = (mainBuffer: Float32Array, applyMulBuffer: Float32Array) => {

    // Find smaller buffer 
    const smallerSize = Math.min(mainBuffer.length, applyMulBuffer.length)
    let agg = 0

    for (let i=0; i<smallerSize; i++) {
        mainBuffer[i] = mainBuffer[i] * applyMulBuffer[i]
        agg += mainBuffer[i]
    }
    
    return agg

}

const buffer_add_all_items = (buffer: Float32Array) => {

    let output = 0
    for (let i=0; i<buffer.length; i++)
        output += buffer[i]

    return output

}

const buffer_div_by_unless_0 = (mainBuffer: Float32Array, applyDivBuffer: Float32Array) => {

    // Find smaller buffer 
    const smallerSize = Math.min(mainBuffer.length, applyDivBuffer.length)

    for (let i=0; i<smallerSize; i++) {

        if (applyDivBuffer[i] < 0.000001) {
            continue;
        }

        mainBuffer[i] = mainBuffer[i] / applyDivBuffer[i]
    }
}

const getHannBuffer = (buffer: Float32Array) => {

    const N = buffer.length
    const hann = new Float32Array(buffer.length)
    for (let n=0; n<N; n++) {

        hann[n] = Math.pow(Math.sin(
            Math.PI * n / N,
        ), 2)

    }

    return hann

}



/**
 * Class capable of procressing RAW samples. 
 * 
 * The ultimate end destination is "into elementary". 
 * 
 * But this class allows intermediate operations, that handle sample rate, channels, etc well. 
 */
export class LoadingSampleBuffer {

    rawSampleBuffer?: AudioBuffer
    
    // Audio data (TODO: some of this can be derived from buffer len / eachother. don't store where redundant)
    buffer: Float32Array
    sampleRate: number
    durationS: number
    lengthInSamples: number

    // Sample data
    sampleName: string

    constructor(buffer: Float32Array, sampleRate: number, durationS: number, lengthInSamples: number, sampleName: string) {

        // Unpack the sampleBuffer information 

        // Num channels 
        // TODO: Better handling for stereo

        const virtualFileName = sampleName

        // Set data for loading
        this.buffer =  buffer
        this.sampleRate = sampleRate
        this.durationS = durationS
        this.lengthInSamples = lengthInSamples

        this.sampleName = sampleName
        
    }


    // ------- PSEUDO-CONSTRUCTORS --------

    static fromAudioBuffer(buffer: AudioBuffer, sampleName: string) {

        return new LoadingSampleBuffer(
            buffer.getChannelData(0), // TODO: Make multi channel friendly :-)
            buffer.sampleRate,
            buffer.duration,
            buffer.length,
            sampleName
        )

    }

    duplicate() {

        return new LoadingSampleBuffer(
            new Float32Array(this.buffer),
            this.sampleRate,
            this.durationS, 
            this.lengthInSamples,
            this.sampleName
        )

    }


    toSampleInfo() {


        // Catalog the virtualFileName in our loaded kit info
        const kitInfo: SampleInfo = {
            channels: [this.sampleName],
            duration: this.durationS,
            length: this.lengthInSamples,
            nativeSampleRate: this.sampleRate,
            buffers: [this.buffer]
        }
        
        return kitInfo



    }

    /**
     * TODO @Marcel: Move away from and delete. 
     */
    addToKitLoader(kitFileLoadMap: {[key: string]: Float32Array}, loadedKitInfo: LoadedKitInfo) {

        // Planning on using this for pitch shifted things.... 
        // It would be GREAT if long term, the kit loaded, and had "pitch shifted alts" in it.... 
        // TODO: Yea ^ these should not be at this high of a level in the loaded config. 


        // Set data for loading
        kitFileLoadMap[this.sampleName] = this.buffer

        // Catalog the virtualFileName in our loaded kit info
        const kitInfo: SampleInfo = {
            channels: [this.sampleName],
            duration: this.durationS,
            length: this.lengthInSamples,
            nativeSampleRate: this.sampleRate,
            buffers: [this.buffer]
        }
        loadedKitInfo[this.sampleName] = kitInfo
        
        return kitInfo



    }


    createPitchedDecendant(semiTones: number) {

        // Get a buffer that represents the the pitched version
        const scaling = Math.pow(2, semiTones/12)
        const analysisWin = 5000 //Math.max(5000 * scaling)
        const analysisHop = 2000
        const synthHop = analysisHop * scaling

        // 1: Duplicate
        const pitched = this.duplicate()

        // 2: Apply pitch shift 
        pitched.shiftPitchOla(analysisWin, analysisHop, synthHop)

        // 3: Rename 
        pitched.sampleName = `${this.sampleName}-${semiTones}`


        // // TODO: Link to the parent (this) at this stage
        // // const siblings = kitInfo.pitchedSiblings ?? {}
        // // siblings[semiTone] = pitchedKitInfo
        // // kitInfo.pitchedSiblings = siblings

        // pitched.saveToFile(pitched.sampleName)

        // Return 
        return pitched

    }



    shiftPitchOla = (analysisWindowLen: number, analysisHopLen: number, synthesisHopLen: number) => {
    
        // Okay... here we go. Gotta iterate through all of the windows. 
        let analysisWindowStart = 0
    
        // For fun... it MAY make sense to just re-construct the sample here too LOL. 
        const scalingFactor = synthesisHopLen / analysisHopLen
        const outputLength = this.buffer.length * scalingFactor
        let outputSignal: Float32Array = new Float32Array(outputLength)
        let hannAggregate: Float32Array = new Float32Array(outputLength)
    
        const jostleLog: {[key: number]: number} = {}
    
        let bufferIdx = 0
        while (analysisWindowStart < this.buffer.length) {
    
            // Okay, lets grab the analysis window out 
            // This might as well just be a big array lmao
            const analysisWindow: Float32Array = new Float32Array(this.buffer.slice(analysisWindowStart, analysisWindowStart + analysisWindowLen))
    
            // Hann the analysis window
            const hann = getHannBuffer(analysisWindow)
            buffer_mul_by(analysisWindow, hann)
    
            
            // The position that the synthesis starts at is going to be a stretched out version of the analysis window start.
            const synthesisWindowStart = Math.ceil(analysisWindowStart * scalingFactor)
    
            // Okay, great. We need to compare this with the ongoing buffer, to find a good placement. 
            let bestOffset = 0
            let highestSimilarity = -10000000
            // TODO
            /**
             * We need to check the overlapping regions, we don't care about the non-overlapping.... that's interesting... 
             * 
             * 
             */
            const offsetSeekWidth = 300
            const seekStep = 8
            for (let possibleOff = -offsetSeekWidth; possibleOff <= offsetSeekWidth; possibleOff+=seekStep) {
    
                // Get the projected overlap of this sample with what's already laid down. 
                const projectedPos = synthesisWindowStart + possibleOff
    
                // The rest of the buffer laying outside this region is gonna be 0... so I can just mult-add them together
                
                // TODO: Optimize. I'm throwing buffers around like they're nothing lol. 
    
                // SubBuffer of output
                const outputSubBuffer = new Float32Array(outputSignal.slice(projectedPos, projectedPos + analysisWindowLen))
    
                // Multiply them together
                const similarity = buffer_mul_by(outputSubBuffer, analysisWindow)
    
                // Add up the pieces (effectively a dot product)
                // OPTIMIZATION: consolidating this into the mul would be faster
                // const similarity = buffer_add_all_items(outputSubBuffer)
    
                if (similarity > highestSimilarity) {
                    bestOffset = possibleOff
                    highestSimilarity = similarity
                }
    
            }
    
            // Now, write to the outputSignal 
            // This specific analysis window needs to be placed at the right synthesis hop.... so there's that. 
            const bestPosition = synthesisWindowStart + bestOffset
            writeBufferToOtherBuffer(analysisWindow, outputSignal, bestPosition)
    
            // Keep track of Hann to normalize
            // TODO / POTENTIAL BUG: Is this right? Or is should the aggregate-hann be getting multiplied together, so that normalizing eliminates any "multiplicative effects"? 
            // As is, I think adding will not do what I want.... 
            // HOWEVER, if I just multiplied by a thing that's 0s..... then I would get 0s..... 
            // I need to know HOW MUCH things were scaled by, and then divide to get down to them being scaled by 1
            /**
             * If hannAggregate started out w/ all 1s.... 
             * -> would grow to be 1, or reflect the scale that was applied.
             * -> 1s could be ignored. All other numbers need to be divided by to get the scale back. 
             * WORTH NOTING THOUGH; the PSOLA algo just adds them together, then divs.
             */
            writeBufferToOtherBuffer(hann, hannAggregate, bestPosition)
    
    
            // Assign this jostle offset to this buffer. 
            jostleLog[bufferIdx] = bestOffset
    
            // Finally; move the analysis window
            analysisWindowStart += analysisHopLen
            bufferIdx += 1
    
        }
    
        // Normalize the output based on Hann aggregate
        // (Need to divide by the Hann signal, unless 0)
        buffer_div_by_unless_0(outputSignal, hannAggregate)
    
        /**
         * INEFFICIENT AREA 
         * Here, we resample the output buffer, to persist the inbound sample rate. 
         * This is not that efficient... full loop back over the everything, v expensive
         */
        let outputSignal2: Float32Array = new Float32Array(this.buffer.length)
        for (let inputIdx=0; inputIdx < this.buffer.length; inputIdx++) {

            // Get the value along the output signal 
            const progressPercent = inputIdx / this.buffer.length
            const hypotheticalOutputIdx = outputSignal.length * progressPercent

            // Get the interp values
            const lowerBoundIdx = Math.floor(hypotheticalOutputIdx)
            const upperBoundIdx = Math.min(Math.ceil(hypotheticalOutputIdx), outputSignal.length-1) // never step over the buffer size when doing this
            const interp = hypotheticalOutputIdx - lowerBoundIdx

            outputSignal2[inputIdx] = AyisenMath.R1.interpolate(outputSignal[lowerBoundIdx], outputSignal[upperBoundIdx], interp)

        }
        

        // Set the relevant state
        this.buffer = outputSignal2
    
        return {jostleLog, outputSignal}
    
    }

    saveToFile(fileName: string) {

        const waveFile = new WaveFile()
        waveFile.fromScratch(1, this.sampleRate ?? 44100, '32f', this.buffer);
    
        const saveFile = async (blob: any) => {
            const a = document.createElement('a');
            a.download = fileName;
            // a.href = URL.createObjectURL(blob);
            a.href = waveFile.toDataURI()
            a.addEventListener('click', (e) => {
              setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
            });
            a.click();
          };
          
        const blob = waveFile.toBuffer()
          
        saveFile(blob);

    }


}


