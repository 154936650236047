
import WebRenderer from '@elemaudio/web-renderer';
import SampleHandler from '../sampling/SampleHandler';
import { atom } from 'jotai';


class AudioCore {


    ctx: AudioContext|null = null;
    core: WebRenderer|null = null;
    node: AudioWorkletNode|null = null;
    sampleHandler: SampleHandler|null = null;

    constructor () {


    }

    initAudioIfNeeded = async () => {

        if (this.core && this.ctx)
            return 

        // Init audio
        this.ctx = new AudioContext();
        this.core = new WebRenderer();

        this.connectAudio();

        this.core.on('load',() => {
            if (this.ctx && this.core)
                this.sampleHandler = new SampleHandler(this.ctx, this.core);
            else {
                console.error("@Prod: Remove :: Unable to connect sample-handler to audio-core");
            }
          });

    }

    connectAudio = async () => {

        if (!this.core || !this.ctx)
            return;

        this.node = await this.core.initialize(this.ctx, {
            numberOfInputs: 1,
            numberOfOutputs: 1,
            // TODO @Marcel: Made this mono, since stero was causing some trouble....
            outputChannelCount: [1],
        });
        
        this.node.connect(this.ctx.destination);

        

    }



}

/** Initialize a single set atom for the audio core. 
 * We use an atom, so that the React Layer can see what's loaded, as well as the Canvas Sims.
*/
export const audioCoreAtom = atom(new AudioCore())


export default AudioCore