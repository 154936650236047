import { R2New } from "./R2";


export interface MotableDelta {

    // Delta to apply
    delta: R2New.Vector,

    // When motion begins in epoch time
    startT: number,

    // Duration (MS)
    duration: number,

}


export interface CurrentMotionState {
    delta: R2New.Vector,
    done: boolean,
}

export const getCurMotionValueu: (delta: MotableDelta) => CurrentMotionState = (delta: MotableDelta) => {

    // Get time now
    const now = Date.now()
    const elapsed = now - delta.startT
    if (elapsed > delta.duration) {
        return {
            done: true,
            delta: delta.delta
        }
    }

    // Not started
    if (now < delta.startT) {
        return {
            done: false,
            delta: R2New.newVector(0, 0)
        }
    }

    // Somewhere between
    const currentOffset = R2New.interpBetweenPoints(R2New.newVector(0, 0), delta.delta, elapsed / delta.duration)
    return {
        done: false,
        delta: currentOffset,
    }

}