import Bubbles from "./bubbles";
import Flinger from "./flinger";
import Paths from "./paths";
import Ripple from "./ripple";
import Tangent from "./tangent";
import Spider from "./spider";
import BungeeFidget from "./bungee";
import Sonic from "./sonic";
import SonicFlinger from "./flinger_sonic";
import Oscillator from "./oscillator";
import Hadron from './hadron';
import Fission from './fission';
import Amoeba from "./amoeba";
import Shatter from './shatter';
import Furball from "./furball";
import Physics from "./physics";


const fidgets = {
    Bubbles,
    Flinger,
    Paths,
    Ripple,
    Tangent,
    Spider,
    BungeeFidget,
    Sonic,
    SonicFlinger,
    Oscillator,
    Hadron,
    Fission,
    Amoeba,
    Shatter,
    Furball,
    Physics
}

export default fidgets