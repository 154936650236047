
import FidgetUtils from '../../../Utilities';
import Fidget, { FidgetConfig, PointEvent } from '../../Core/FidgetClass';
import AyisenMath from '../../../AyisenMath';
import { R2New } from '../../../AyisenMath/R2';
import Fidget3D from '../../Core/FidgetCore3D';

import * as THREE from 'three';
import Random from '../../../AyisenMath/Random';
import { FidgetId } from '../../../../constants/fidgetConsts';

// JS Library for Ripple Animations


export interface Bubble {

    breathPeriod: number,

    maxRad: number,

    initial: {
        t: number,
    }

    current: {
        location: R2New.Vector,
        radius: number, 
        t: number,
        color: string,
    }

    // Running Vars
    radius?: number,

}


export default class Hadron extends Fidget3D {

    // Class members
    bubbles: Bubble[] = [];
    dragTrigger: boolean = true;

    edgeConfigs: {width: number, color: THREE.ColorRepresentation, rotationRate: number}[]
    lineSegs: THREE.LineSegments[]
    sphere: THREE.Mesh

    constructor(canvas: HTMLCanvasElement, config: FidgetConfig = {}) {

        // Normal fidget constructor
        super(canvas, FidgetId.HADRON, config)


        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        const material = new THREE.MeshBasicMaterial( { color: 'rgb(100, 200, 255)', opacity: 0.5 } );
        const cube = new THREE.Mesh( geometry, material );
        // scene.add( cube );

        this.edgeConfigs = [
            {
                width: 1, 
                color: 'red', 
                rotationRate: 0.015
            },
            {
                width: 1, 
                color: 'white', 
                rotationRate: -0.015
            },
            {
                width: 2, 
                color: 'purple', 
                rotationRate: -0.01
            },
            {
                width: 2, 
                color: 'green', 
                rotationRate: +0.01
            },
        ]

        this.lineSegs = this.edgeConfigs.map(config => {
            // const edges = new THREE.EdgesGeometry( new THREE.BoxGeometry(config.width, config.width, config.width) );
            const edges = new THREE.EdgesGeometry( new THREE.TorusGeometry(config.width) );
            const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: config.color, linewidth: 500 } ) );
            this.scene.add( line );
            return line;
        })

        // const edges = new THREE.EdgesGeometry( new THREE.BoxGeometry(1, 1, 1) );
        // const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 'red', linewidth: 500 } ) );
        // scene.add( line );

        // const edges2 = new THREE.EdgesGeometry( new THREE.BoxGeometry(1, 1, 1) );
        // const line2 = new THREE.LineSegments( edges2, new THREE.LineBasicMaterial( { color: 'white', linewidth: 500 } ) );
        // scene.add( line2 );
        

        const sphereGeo = new THREE.SphereGeometry(0.5);
        const sphereMat = new THREE.MeshBasicMaterial( { color: 'orange', opacity: 0.1})
        this.sphere = new THREE.Mesh(sphereGeo, sphereMat)
        this.sphere.position.z = 2;
        this.scene.add(this.sphere)
        
        const geometry2 = new THREE.PlaneGeometry(5, 5);
        const material2 = new THREE.MeshBasicMaterial({ color: 'blue'})
        const floor = new THREE.Mesh( geometry2, material2)
        // scene.add ( floor );

        cube.position.z = 2
        for (let line of this.lineSegs) {
            line.position.z = 2
        }

        this.camera.position.z = 5;

    }


    // Bubble management
    addBubble (x: number, y: number, period: number, r: number, color: string) {


        this.sphere.material = new THREE.MeshBasicMaterial( { color: Random.randomThreeColor(), opacity: 0.1})
        for (let line of this.lineSegs) {
            line.material = new THREE.LineBasicMaterial({ color: Random.randomThreeColor()})
        }

    }

    onDrag = (e: PointEvent) => {

        if (this.dragTrigger)
            this.addBubble(e.x, e.y, AyisenMath.Random.getRandomFloat(2, 10), AyisenMath.Random.getRandomInt(50, 100), AyisenMath.Random.randomColor());
    }

    onClick = (e: PointEvent) => {

        // Add bubble 
        this.addBubble(e.x, e.y, AyisenMath.Random.getRandomFloat(2, 10), AyisenMath.Random.getRandomInt(50, 100), AyisenMath.Random.randomColor());

    }



    // Render Frame
    renderFrame = async () => {

        for (let lineIdx =0; lineIdx < this.lineSegs.length; lineIdx ++) {
            this.lineSegs[lineIdx].rotation.x += this.edgeConfigs[lineIdx].rotationRate
            this.lineSegs[lineIdx].rotation.y += this.edgeConfigs[lineIdx].rotationRate
        }

            
        this.camera.rotateOnAxis(new THREE.Vector3(0, 0, 1), 0.001)
        this.renderer.render( this.scene, this.camera );

        

    }

}



// export default class Bubbles {

//     constructor(canvas) {

//         // Save canvas & context
//         this.canvas = canvas;
//         this.ctx = canvas.getContext('2d');

//         this.dragTrigger = true;

//         // Attach canvas handlers

//         // Ripple management
//         this.currentBubbles = [];
        
//         // Bind functions
//         this.renderFrame = this.renderFrame.bind(this);
//         this.handleClick = this.handleClick.bind(this);
//         this.handleDrag = this.handleDrag.bind(this);


//         // Simulation handlers
//         const simHandlers = {
//             onClick: e => this.handleClick(e),
//             onDrag: e => this.handleDrag(e),
//         }

//         // Attach simulator
//         this.simulator = new Simulator(canvas, this.renderFrame, ()=> {}, simHandlers);
//         this.simulator.simulate();

//     }

//     }


//     // Animate frame
//     renderFrame () {

//         // Render / Update ripples

//         this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

//         const toRemove = [];

//         // Get meta breath multiplier
//         // let metaT =  (Date.now() - this.simulator.initT) / 1000;
//         // let metaInterp = Math.sin(2 * Math.PI * (metaT / 2))
//         // metaInterp = (metaInterp + 1) / 2;
//         let metaInterp = 1;





//         this.currentBubbles.forEach((bubble, idx) => {


//             // Set current radius
//             const relT = (Date.now() - bubble.initial.t) / 1000;
//             let interpVal = Math.sin(2 * Math.PI * (relT / bubble.breathPeriod));
            
//             // Range : -1 to 1 -> 0 to 1
//             interpVal = (interpVal + 1) / 2;
//             interpVal *= metaInterp

//             // Set Radus : range 0 to maxRad
//             bubble.radius = bubble.maxRad * (interpVal)

//             // Set opacity : range 0.2 to 0.8
//             const opacity = (interpVal * 0.6) + 0.2

//             canvasUtils.drawBubble(
//                 this.ctx, 
//                 {x: bubble.current.x, y: bubble.current.y}, 
//                 bubble.radius, 
//                 bubble.current.color, 
//                 opacity
//             );
//         });

//         // Remove as needed. 
//         for (let idx of toRemove)
//             this.currentBubbles.splice(idx, 1);

//     }






// }