import { PointEvent } from "../Core/FidgetClass";

/**
 * NOTES (DELETE)
 * 
 * I wonder if there is any benefit to having a NON ephemeral engine? 
 * 
 * This would be something that can store data in the canvas. 
 * 
 * It would be responsible for 
 * - Reading behaviors / declaring behaviors 
 * - Placing rendering information. 
 * - Saving information from state
 * - Static :: loading information from state. 
 * 
 * I feel like there's a world where we should have the information at the Fidget level. 
 * 
 * WHAT WOULD ENGINES UNLOCK
 * - Being able to use different dataformats in the same canvas (pritives as opposed to locked off things)
 * This would be cool bc you could add multiple things to the same canvas. 
 * I don't think there are any reasons I'd wanna do that rn... but could be? 
 * 
 * For example, I could maybe re-use some engines? idk tho, i think this works best for ephemeral things. 
 * 
 * For things that stick on the canvas; I think I would prefer having OBJECTS that you can 
 * - Render from 
 * - Are stateful 
 * - Are loadable. 
 * 
 * This way, any fidget can decide how they put these objects on the map, but they handle their own behaviors. 
 * 
 * They should be able to 
 * - Render 
 * - Hold state
 * - Load from state
 * - Perform boundary / movement operations (maybe they derive from "MovableObj" if they need to?)
 * - Have a "beforeFrameEffects" / "afterFrameEffects" callback for things like moving, etc

 * 
 */


/**
 * An ephemeral engine is one which ONLY renders ephemeral data on the canvas. 
 * (i.e dragging cursor, and sparks fly off of it)
 * 
 * If adding something which stores permenant data that saves, persists, etc :: please use a Fidget directly, or a more stable engine
 */
export class EphemeralEngine {

    // Constructor doesn't do much 
    constructor() {

    }

    // Overridable actions that can cause state updates! 
    onClick(e: PointEvent) {

    }

    onDrag(e: PointEvent) {

    }

    onMouseMove(e: PointEvent) {

    }

    onMouseDown(e: PointEvent) {

    }

    onMouseUp(e: PointEvent) {

    }

    onMouseLeave (e: PointEvent) {

    }

    // Render based on state! 
    async renderFrame(ctx: CanvasRenderingContext2D | null) {

    }

}